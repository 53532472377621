
























































import { Component, Vue } from "vue-property-decorator";
import { ICityCreate } from "@/interfaces";
import { dispatchCreateCity, dispatchGetCities } from "@/store/admin/actions";
import { getDistanceFromLatLonInKm } from "@/utils";
import { readHasAdminAccess } from "@/store/main/getters";

@Component
export default class CreateCity extends Vue {
  public valid = false;
  public name: string = "";
  public isActive: boolean = true;
  public coatOfArms?: File;
  public currentPlace?: any;
  public center = { lat: 48.228146, lng: 8.3844681 };
  public markerPosition = { lat: 48.228146, lng: 8.3844681 };
  private radius?: number;

  private isWhitelabel = false;
  private oneSignalAppId?: string;
  private oneSignalApiKey?: string;
  private oneSignalExpirationDate?: Date;

  public setPlace(place) {
    this.currentPlace = place;
    this.center.lat = place.geometry.location.lat();
    this.center.lng = place.geometry.location.lng();

    this.markerPosition = {
      lat: this.center.lat,
      lng: this.center.lng,
    };

    const lat1 = place.geometry.viewport.getNorthEast().lat();
    const lng1 = place.geometry.viewport.getNorthEast().lng();
    const lat2 = place.geometry.viewport.getSouthWest().lat();
    const lng2 = place.geometry.viewport.getSouthWest().lng();
    this.radius = getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2);
  }

  public imageRules(value: any) {
    return !value;
  }

  public async mounted() {
    await dispatchGetCities(
      this.$store,
      readHasAdminAccess(this.$store) ?? false
    );
    this.reset();
  }

  public reset() {
    this.name = "";
    this.isActive = true;
    this.center = { lat: 48.228146, lng: 8.3844681 };
    this.markerPosition = { lat: 48.228146, lng: 8.3844681 };
    this.radius = undefined;
    this.oneSignalAppId = undefined;
    this.oneSignalApiKey = undefined;
    this.oneSignalExpirationDate = undefined;
    this.isWhitelabel = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const city: ICityCreate = {
        name: this.name,
        is_active: this.isActive,
        radius: this.radius!,
        latitude: this.markerPosition.lat,
        longitude: this.markerPosition.lng,
        one_signal_app_id: this.oneSignalAppId,
        one_signal_api_key: this.oneSignalApiKey,
        one_signal_api_key_expiration_date: this.oneSignalExpirationDate,
      };
      if (this.coatOfArms) city.coat_of_arms = this.coatOfArms;

      await dispatchCreateCity(this.$store, city);
      this.$router.push("/main/admin/cities");
    }
  }
}
